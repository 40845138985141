var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"mb-6"},[_c('div',{staticClass:"heading d-flex align-center mb-3"},[_c('h3',{staticClass:"title"},[_vm._v(" Manage user flairs ")]),_c('v-spacer'),_c('v-btn',{staticClass:"unwidth unheight align-self-stretch",attrs:{"color":"grey300","tile":"","small":"","ripple":false,"loading":_vm.loading.list},on:{"click":_vm.fetchApiData}},[_c('fai',{staticClass:"grey100--text",attrs:{"icon":['fas','sync']}})],1)],1),_c('v-data-table',{staticClass:"rounded-0 data-table",attrs:{"item-key":"_id","headers":_vm.headers,"items":_vm.entries,"options":_vm.options,"footer-props":{ itemsPerPageOptions: [5, 10, 25] },"server-items-length":_vm.total,"expanded":_vm.expanded,"loading":_vm.loading.list},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('Username',{staticClass:"d-inline mr-2",attrs:{"user":{name: value, flairs: [item]}}})]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('UserAvatar',{staticClass:"mr-2",attrs:{"user":item.creator,"size":"24"}}),_c('Username',{attrs:{"user":item.creator,"no-flairs":""}})],1)]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("relativeTime")(new Date(value).getTime(),'twitter')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateFormat")(new Date(value).getTime(),'YYYY-MM-DD HH:mm:ss')))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('fai',{staticClass:"primary500--text mr-2 link",attrs:{"icon":['fad', 'pencil']},on:{"click":function($event){return _vm.onExpandItem(item)}}}),_c('fai',{staticClass:"primary500--text link",attrs:{"icon":['fad', 'trash']},on:{"click":function($event){return _vm.onDelete(item)}}})]}},{key:"expanded-item",fn:function(ref){
var head = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"grey800 py-3 data-detail",attrs:{"colspan":head.length}},[_c('AdminFlairForm',{attrs:{"value":item,"loading":_vm.loading.create},on:{"submit":_vm.onSetFlair}})],1)]}}])})],1),_c('section',[_c('v-expansion-panels',{attrs:{"accordion":"","flat":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"grey600"}},[_vm._v(" Create new flair ")]),_c('v-expansion-panel-content',{attrs:{"color":"grey800"}},[_c('AdminFlairForm',{attrs:{"loading":_vm.loading.create,"is-new":""},on:{"submit":_vm.onSetFlair},model:{value:(_vm.newFlairForm),callback:function ($$v) {_vm.newFlairForm=$$v},expression:"newFlairForm"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }