<template>
  <div>
    <!-- stfu  -->
    <section class="mb-6">
      <div class="heading d-flex align-center mb-3">
        <h3 class="title">
          Manage user flairs
        </h3>
        <v-spacer />
        <v-btn
          color="grey300"
          tile small class="unwidth unheight align-self-stretch"
          :ripple="false" :loading="loading.list"
          @click="fetchApiData"
        >
          <fai :icon="['fas','sync']" class="grey100--text" />
        </v-btn>
      </div>

      <v-data-table
        class="rounded-0 data-table"
        item-key="_id"
        :headers="headers"
        :items="entries"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
        :server-items-length="total"
        :expanded="expanded"
        :loading="loading.list"
        @pagination="onPagination"
      >
        <template #item.name="{ item, value }">
          <Username class="d-inline mr-2" :user="{name: value, flairs: [item]}" />
        </template>

        <template #item.createdBy="{ item }">
          <div class="d-flex align-center">
            <UserAvatar :user="item.creator" size="24" class="mr-2" />
            <Username :user="item.creator" no-flairs />
          </div>
        </template>

        <template #item.createdAt="{ value, item }">
          <v-tooltip top color="primary500">
            <template #activator="{on}">
              <span v-on="on">{{ new Date(value).getTime() | relativeTime('twitter') }}</span>
            </template>
            <span>{{ new Date(value).getTime() | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </v-tooltip>
        </template>

        <template #item.actions="{ item }">
          <fai
            :icon="['fad', 'pencil']"
            class="primary500--text mr-2 link"
            @click="onExpandItem(item)"
          />

          <fai
            :icon="['fad', 'trash']"
            class="primary500--text link"
            @click="onDelete(item)"
          />
        </template>

        <!-- transaction info -->
        <template #expanded-item="{ headers: head, item }">
          <td :colspan="head.length" class="grey800 py-3 data-detail">
            <AdminFlairForm
              :value="item"
              :loading="loading.create"
              @submit="onSetFlair"
            />
          </td>
        </template>
      </v-data-table>
    </section>

    <!-- create bot -->
    <section>
      <v-expansion-panels accordion flat tile>
        <v-expansion-panel>
          <v-expansion-panel-header color="grey600">
            Create new flair
          </v-expansion-panel-header>
          <v-expansion-panel-content color="grey800">
            <AdminFlairForm
              v-model="newFlairForm"
              :loading="loading.create"
              is-new
              @submit="onSetFlair"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AdminFlairForm from './components/AdminFlairForm'

const newFlairFormDefault = () => ({
  name: null,
  color: '#FFFFFF',
  type: null,
  value: null,
})

export default {
  components: { AdminFlairForm },
  data() {
    return {
      loading: {
        list: false,
        create: false,
      },
      total: 0,
      expanded: [],
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        { text: 'Flair', value: 'name', align: 'start' },
        { text: 'Last updated by', value: 'createdBy' },
        {
          text: 'Created', value: 'createdAt', align: 'end',
        },
        {
          text: null, value: 'actions', sortable: false, align: 'end',
        },
      ],
      newFlairForm: newFlairFormDefault(),
      entries: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
  },
  methods: {
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item._id === expandedItem?._id) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    onPagination() {
      this.expanded = []
    },
    async fetchApiData() {
      this.loading.list = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.flairs.list', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
        })

        this.entries = entries
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading.list = false
      }
    },
    async onSetFlair(form) {
      this.loading.create = true

      try {
        const flair = await this.$socket.request('admin.flairs.set', form)

        this.newFlairForm = newFlairFormDefault()
        this.$toast.success(`User flair "${flair.name}" set successfully.`)

        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
    async onDelete({ _id }) {
      this.loading.create = true

      try {
        await this.$socket.request('admin.flairs.delete', _id)

        this.$toast.success(`User flair "${_id}" deleted successfully.`)
        this.fetchApiData()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.create = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.user-obj {
  overflow: auto;
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
