<template>
  <v-row class="pt-4">
    <v-col cols="12" md="12">
      <v-text-field
        v-model="form.name"
        solo dense flat
        placeholder="Name (shown when hovering over icon)"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col cols="12" md="12">
      <h5 class="text-caption font-weight-bold mb-2 primary500--text text-uppercase">
        Color
      </h5>
      <v-color-picker v-model="form.color" class="grey900" />
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        v-model="form.type"
        :items="['image', 'icon']"
        filled dense flat
        class="rounded-0 mr-2"
        background-color="grey400"
        hide-details
        placeholder="Prefix type"
      />
    </v-col>
    <v-col v-if="form.type === 'image'" cols="12" md="6">
      <v-text-field
        v-model="form.value"
        solo dense flat
        placeholder="URL (stable cdn &amp; smol pls)"
        hide-details
        background-color="grey600"
      />
    </v-col>
    <v-col v-else-if="form.type === 'icon'" cols="12" md="6">
      <v-select
        v-model="form.value"
        :items="['crown', 'robot', 'swords', 'sword', 'cog', 'sparkles', 'shield-check', 'badge-check']"
        filled dense flat
        class="rounded-0 mr-2"
        background-color="grey400"
        hide-details
        placeholder="Icon"
      >
        <template #prepend-inner>
          <fai v-if="form.value" :icon="['fad', form.value]" class="mr-2" />
        </template>
        <template #item="{item}">
          <fai :icon="['fad', item]" class="mr-2" />
          <span v-text="item" />
        </template>
      </v-select>
    </v-col>
    <v-col cols="12" class="text--primary">
      <span class="faded-5">Preview: </span>
      <Username
        v-if="valid"
        class="d-inline"
        :user="{name: 'robloxkiller2004', flairs: [{
          ...form,
          prefix: {
            type: form.type,
            value: form.value
          }
        }]}"
      />
      <span v-else>Form not valid</span>
    </v-col>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn
        class="rounded-0 font-weight-bold"
        color="blue700 blue300--text"
        :ripple="false"
        :loading="loading"
        @click="onSubmit"
      >
        <fai :icon="['fas', 'check']" class="mr-2" />
        {{ isNew ? 'Create' : 'Update' }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: null,
    }
  },
  computed: {
    valid() {
      return Object
        .entries(this.form)
        .filter(([k]) => ['name', 'color', 'type ', 'value'].includes(k))
        .map(([, v]) => v)
        .every(Boolean)
    },
  },
  watch: {
    value(val) {
      if (JSON.stringify(val) === JSON.stringify(this.form)) return

      if (typeof val.prefix === 'object') {
        this.form = {
          ...val,
          type: val.prefix.type,
          value: val.prefix.value,
        }
      } else {
        this.form = { ...val }
      }
    },
    'form.type': function onPrefixChange(newval, oldval) {
      if (!oldval) return

      this.form.value = null
    },
    form: {
      handler(val) {
        if (val.usesLeft > val.maxUses) {
          this.form.usesLeft = val.maxUses
        }

        this.$emit('input', { ...val })
      },
      deep: true,
    },
  },
  created() {
    if (typeof this.value.prefix === 'object') {
      console.log(this.value.prefix.value)
      this.form = {
        ...this.value,
        type: this.value.prefix.type,
        value: this.value.prefix.value,
      }
    } else {
      this.form = { ...this.value }
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form)
    },
  },
}
</script>
